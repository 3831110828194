import React from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import RadioButton from './RadioButton';

export default function TableRadioItem({ item, checked, onClick }) {
  return (
    <InputWrapper checked={checked} onClick={onClick}>
      <TextWrapper>
        <PStyled>{item.title}</PStyled>
        <PStyled2>{item.subtitle}</PStyled2>
      </TextWrapper>
      <RadioButton selected={checked} />
    </InputWrapper>
  );
}

const TextWrapper = styled.div`
  width: 80%;
`;

const PStyled = styled(D.P)`
  font-family: BrownStd;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;
const PStyled2 = styled(D.P)`
  font-family: BrownStd;
  font-size: 12px;
  line-height: 15px;
  color: grey;
`;
interface InputWrapper {
  checked: boolean;
}
const InputWrapper = styled.div`
  background: #cae2c3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #cae2c3;
  border-radius: 1px;
  min-width: 50%;
  padding: 20px;
  height: auto;
  cursor: pointer;
  ${({ checked }: InputWrapper) => {
    return checked ? `  border: 2px solid #003a1c;` : null;
  }}
`;
