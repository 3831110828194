import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from './Button';
import { TextInput } from './TextInput';
import { AnswerWrapper } from './SharedLayouts';
import { D } from '@dayetopia/library';
import UnitButtons from './BMIUnitButtons';

export default function BMIWeight({ onChange, answer }) {
  const [unit, setUnit] = useState('kg');
  const [valueInKg, setValueInKgs] = useState(answer);
  const [valueInStone, setValueInStone] = useState();
  const [valueInPounds, setValueInPounds] = useState();

  const kgsPerStone = 6.35029;
  const kgsPerPound = 0.453592;

  useEffect(() => {
    if (!valueInPounds && !valueInStone) return;
    const poundsToKgs = (valueInPounds || 0) * kgsPerPound;
    const stoneToKgs = (valueInStone || 0) * kgsPerStone;
    setValueInKgs(poundsToKgs + stoneToKgs);
  }, [valueInPounds, valueInStone]);

  useEffect(() => {
    onChange(valueInKg);
  }, [valueInKg]);

  function handleLargeUnitChange(event) {
    const valueAsNumber = Number(event.target.value);
    if (isNaN(valueAsNumber)) return;
    if (unit === 'kg') {
      setValueInKgs(valueAsNumber);
    } else {
      setValueInStone(valueAsNumber);
    }
  }

  function handleSmallUnitChange(event) {
    const valueAsNumber = Number(event.target.value);
    if (isNaN(valueAsNumber)) return;
    setValueInPounds(valueAsNumber);
  }

  return (
    <AnswerWrapper>
      <InputWrapper>
        <TextInputStyled
          placeholder={`0`}
          defaultValue={unit === 'kg' ? valueInKg : valueInStone}
          onChange={handleLargeUnitChange}
        />
        <P3Styled> {unit === 'stone' ? 'st' : 'kg'}</P3Styled>
      </InputWrapper>
      {unit === 'stone' && (
        <InputWrapper>
          <TextInputStyled
            placeholder={`0`}
            value={valueInPounds}
            onChange={handleSmallUnitChange}
          />
          <P3Styled> lbs</P3Styled>
        </InputWrapper>
      )}

      <UnitButtons
        unitOne={{ text: 'st & lbs', id: 'stone' }}
        unitTwo={{ text: 'kg', id: 'kg' }}
        onChange={setUnit}
        currentUnit={unit}
      />
    </AnswerWrapper>
  );
}

export const TextInputStyled = styled(TextInput)`
  margin-bottom: 13px;
  margin-right: 10px;
  @media only screen and (max-width: 500px) {
    width: 300px;
  }
`;
const P3Styled = styled(D.P)`
  margin-left: -45px;
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
`;
