import React, { useEffect, useRef, useContext } from 'react';

import QuestionAndAnswer from './QuesQuestionAndAnswer';
import axios from 'axios';
import pharmacyUrl from '../utils/urls';
import { Context } from './Context';
import { Question } from '../pages/questionnaire';

interface Props {
  questionArr: Question[];
  sessionId: string;
  setQuestionArr: (a: any) => void;
  questionnaireComplete: boolean;
}

export default function AllQuestions({
  questionArr,
  sessionId,
  setQuestionArr,
  questionnaireComplete,
}: Props) {
  const lastQuestionRef = useRef<HTMLInputElement>(null);
  const { dispatch } = useContext(Context);

  useEffect(scrollToNextQuestion, [questionArr]);

  function scrollToNextQuestion() {
    if (lastQuestionRef && lastQuestionRef.current) {
      lastQuestionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  function submitAnswer(value: any, questionId: string) {
    axios
      .post(
        `${pharmacyUrl(
          window.location.hostname
        )}/sessions/${sessionId}/question/${questionId}/submit`,
        JSON.stringify(value),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      .then(res => {
        const { data } = res;

        setQuestionArr(data.questions);
        localStorage.setItem('session-id', data.id);
        localStorage.setItem(data.id, JSON.stringify(data.questions));
        localStorage.setItem(
          `${data.id}-completion`,
          JSON.stringify(data.completion)
        );
        localStorage.setItem(
          `${data.id}-status`,
          data.status
        );

        dispatch({
          type: 'setQuestionnaireCompletion',
          data: data.completion,
        });

        dispatch({
          type: 'setQuestionnaireStatus',
          data: data.status,
        });
      });
  }

  return (
    <>
      {questionArr.map((q: Question, i) => {
        const isLastQuestion = i + 1 === questionArr.length;
        const isFirstQuestion = i === 0;
        const scrollToThisQuestion =
          isLastQuestion && !isFirstQuestion && !questionnaireComplete;

        return (
          <div key={i}>
            {scrollToThisQuestion && <div ref={lastQuestionRef} />}
            <QuestionAndAnswer
              question={q}
              onChange={val => {
                submitAnswer(val, q.id);
              }}
            />
          </div>
        );
      })}
    </>
  );
}
