import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
// import NavProgress from '../components/NavProgress';
import { D } from '@dayetopia/library';
import smoothscroll from 'smoothscroll-polyfill';

import ContentWrapper from '../components/ContentWrapper';
import axios from 'axios';
import pharmacyUrl from '../utils/urls';
import { Context } from '../components/Context';
import QuestionnaireContent from '../components/QuesContent';

export default function Questionnaire() {
  smoothscroll.polyfill();
  const [sessionId, setSessionId] = useState('');
  const [questionArr, setQuestionArr] = useState([]);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    handleSession();
  }, []);

  function handleSession() {
    const expiryTime = localStorage.getItem('expiry-time');
    const now = new Date().getTime();
    const sessionExpired = expiryTime && Number(expiryTime) < now;
    if (sessionExpired) {
      localStorage.clear();
    }
    const sessionIdFromStorage = localStorage.getItem('session-id');
    const sessionExists = sessionIdFromStorage;

    if (sessionExists) {
      populateStateFromLocalStorage(sessionIdFromStorage || '');
    } else {
      createSession();
    }
  }

  function populateStateFromLocalStorage(sessionIdFromStorage: string) {
    setSessionId(sessionIdFromStorage);
    const questionsFromStorage = localStorage.getItem(sessionIdFromStorage);
    const completionFromStorage = localStorage.getItem(
      `${sessionIdFromStorage}-completion`
    );
    const statusFromStorage = localStorage.getItem(
      `${sessionIdFromStorage}-status`
    );

    if (questionsFromStorage) {
      setQuestionArr(JSON.parse(questionsFromStorage));
      dispatch({
        type: 'setQuestionnaireCompletion',
        data: Number(completionFromStorage),
      });
    }
    dispatch({
      type: 'setQuestionnaireStatus',
      data: (statusFromStorage || ''),
    });
  }

  function createSession() {
    axios // fetch was returning a readable stream so using axios instead
      .get(pharmacyUrl(window.location.hostname) + '/sessions/create')
      .then(({ data }) => {
        setSessionId(data.id);
        setQuestionArr(data.questions);
        const now = new Date().getTime();
        const oneHour = 1000 * 60 * 60;
        const expiryTime = now + oneHour;
        localStorage.setItem('expiry-time', expiryTime.toString());
      })
      .catch(err => console.log(err));
  }

  return (
    <PageContainer>
      <LayoutStyled>
        <H1Styled>Daye's Contraception Consultation</H1Styled>
        <QuestionnaireContent
          questionArr={questionArr}
          sessionId={sessionId}
          setQuestionArr={setQuestionArr}
        />
      </LayoutStyled>
    </PageContainer>
  );
}

export type Question = {
  id: string;
  answer: string;
  answerOptions: any[];
  answers: any;
  title: string;
  subtitle: string;
  type: string;
};

const LayoutStyled = styled(ContentWrapper)`
  padding-top: 20px;
  padding-bottom: 150px;
`;

const H1Styled = styled(D.H1)`
  font-weight: 500;
  font-size: 24px;
  line-height: 41px;
  margin-top: 120px;

  @media only screen and (max-width: 500px) {
    margin-bottom: -40px;
    margin-left: 20px;
    margin-top: 100px;
  }
`;

const PageContainer = styled.div`
  margin: auto;
  background: #eaf5e8;
  min-height: 100vh;
  animation: fadein 0.8s;
`;
