import React from 'react';

import DateInput from './DateInput';
import Table from './Table';
import MultiTable from './MultiTable';
import BMI from './BMI';
import { Question } from '../pages/questionnaire';
import ExitMessage from './QuesExitMessage';

interface Props {
  onChange: (a: any) => void;
  question: Question;
}

export default function QuestionAndAnswer({ onChange, question }: Props) {
  const { answer, answerOptions, answers, title, subtitle, type } = question;
  switch (type) {
    case 'datePicker':
      return (
        <DateInput
          onChange={onChange}
          answer={answer}
          title={title}
          subtitle={subtitle}
        />
      );
    case 'table':
      return (
        <Table
          onChange={onChange}
          answer={answer}
          answerOptions={answerOptions}
          title={title}
          subtitle={subtitle}
        />
      );
    case 'bmi':
      return <BMI question={question} onChange={onChange} />;

    case 'tableMulti':
      return (
        <MultiTable
          onChange={onChange}
          answers={answers}
          answerOptions={answerOptions}
          title={title}
          subtitle={subtitle}
        />
      );

    case 'message':
      return <ExitMessage question={question} />;
    default:
      return null;
  }
}
