import React, { useEffect, useState, useRef, useContext } from 'react';

import { Context } from './Context';
import ExitMessage from './QuesExitMessage';
import AllQuestions from './QuesAllQuestions';
import FinalSubmitBtn from './QuesFinalSubmitBtn';
import { Question } from '../pages/questionnaire';

interface Props {
  questionArr: Question[];
  sessionId: string;
  setQuestionArr: (a: any) => void;
}

export default function QuestionnaireContent({
  questionArr,
  sessionId,
  setQuestionArr,
}: Props) {
  const { state } = useContext(Context);
  const { completion, questionnaireStatus } = state;

  useEffect(scrollToBottomOfPage, [completion]);
  const questionnaireComplete = completion === 1;

  function scrollToBottomOfPage() {
    if (questionnaireComplete) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }

  const showFinalSubmitBtn = questionnaireComplete && questionnaireStatus === 'succeeded';

  return (
    <>
      <AllQuestions
        sessionId={sessionId}
        setQuestionArr={setQuestionArr}
        questionArr={questionArr}
        questionnaireComplete={questionnaireComplete}
      />

      {showFinalSubmitBtn && (
        <FinalSubmitBtn
          questionnaireComplete={questionnaireComplete}
          questionArr={questionArr}
        />
      )}
    </>
  );
}
