import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import TableRadioItem from './TableRadioItem';
import Question from './QuesQuestion';
import { AnswerWrapper, QuestionAndAnswerContainer } from './SharedLayouts';

export default function MultiTable({
  onChange,
  answers,
  answerOptions,
  title,
  subtitle,
}: Props) {
  const [arrayOfSelectedItems, addSelectedItem] = useState<number[]>([]);

  useEffect(() => {
    if (answers) {
      const answerIds = answers.map(obj => obj.id);
      const newArr = [...arrayOfSelectedItems, ...answerIds];
      addSelectedItem(newArr);
    }
  }, []);
  const noneOfAboveId = -1;
  function clickItem(o: AnswerOption) {
    const alreadySelected = arrayOfSelectedItems.includes(o.id);
    if (alreadySelected) {
      // remove from array
      const newArr = arrayOfSelectedItems.filter(id => id !== o.id);
      addSelectedItem(newArr);
      const arrOfObjects = newArr.map(id => ({ id }));
      const arrayOrUndefined =
        arrOfObjects.length > 0 ? arrOfObjects : undefined;
      onChange(arrayOrUndefined);
    } else {
      const newArr = [...arrayOfSelectedItems, o.id].filter(
        id => id !== noneOfAboveId
      );

      addSelectedItem(newArr);
      const arrOfObjects = newArr.map(id => ({ id }));
      onChange(arrOfObjects);
    }
  }

  return (
    <QuestionAndAnswerContainer>
      <Question title={title} subtitle={subtitle} />
      <StyledAnswerWrapper>
        {answerOptions.map((o: AnswerOption, i) => {
          const checked = arrayOfSelectedItems.includes(o.id);
          return (
            <InputWrapper
              key={i}
              checked={checked}
              onClick={() => clickItem(o)}
            >
              <TextWrapper>
                <PStyled>{o.title}</PStyled>
                <PStyled2>{o.subtitle}</PStyled2>
              </TextWrapper>
              <D.Checkbox onClick={() => clickItem(o)} checked={checked} />
            </InputWrapper>
          );
        })}
        <TableRadioItem
          onClick={() => {
            addSelectedItem([noneOfAboveId]);
            onChange([{ id: noneOfAboveId }]);
          }}
          item={{ title: 'None of the above' }}
          checked={arrayOfSelectedItems.includes(noneOfAboveId)}
        />
      </StyledAnswerWrapper>
    </QuestionAndAnswerContainer>
  );
}
interface Props {
  answers: any[];
  onChange: (val: any) => void;
  answerOptions: any[];
  title: string;
  subtitle: string;
}
type AnswerOption = {
  id: number;
  title: string;
  subtitle: string;
};
const TextWrapper = styled.div`
  width: 80%;
`;

const PStyled = styled(D.P)`
  font-family: BrownStd;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;
const PStyled2 = styled(D.P)`
  font-family: BrownStd;
  font-size: 12px;
  line-height: 15px;
  color: grey;
  margin-top: 0;
`;
interface InputWrapper {
  checked: boolean;
}
const InputWrapper = styled.div`
  background: #cae2c3;
  border: 2px solid #cae2c3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1px;
  min-width: 50%;
  padding: 20px 20px;
  min-height: 49px;
  cursor: pointer;
  ${({ checked }: InputWrapper) => {
    return checked ? `  border: 2px solid #003a1c;` : null;
  }}
`;

const StyledAnswerWrapper = styled(AnswerWrapper)`
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-column-gap: 20px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
