import React, { useState } from 'react';
import styled from 'styled-components';
import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  isDefaultNextDelivery,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  CALENDAR_MONTHS,
} from '../utils/calendar';
import { D } from '@dayetopia/library';

interface Props {
  current: any;
  setCurrent: any;
  closeCalendar: any;
}

export function Calendar({ current, setCurrent, closeCalendar }: Props) {
  const [month, setMonth] = useState((+current.getMonth() + 1) % 13);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  let calendarStartDate = new Date().getFullYear() - 18;

  const [year, setYear] = useState(calendarStartDate);

  function getCalendarDates() {
    const calendarMonth = month || +current.getMonth() + 1;
    const calendarYear = year || current.getFullYear();
    return calendar(calendarMonth, calendarYear);
  }

  function resolveStateFromDate(date: Date) {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();
    setCurrent(isDateObject ? date : null);
    setMonth(+_date.getMonth() + 1);
    setYear(_date.getFullYear());
  }

  function gotoDate(date: Date) {
    return () => {
      if (!(current && isSameDay(date, current))) {
        closeCalendar();
        resolveStateFromDate(date);
      }
    };
  }

  function gotoPreviousMonth() {
    const { month: newMonth, year: newYear } = getPreviousMonth(month, year);
    setMonth(newMonth);
    setYear(newYear);
  }

  function gotoNextMonth() {
    const { month: newMonth, year: newYear } = getNextMonth(month, year);
    setMonth(newMonth);
    setYear(newYear);
  }

  function renderMonthAndYear() {
    const months = Object.keys(CALENDAR_MONTHS);
    const currentMonth = months[Math.max(0, Math.min(month - 1, 11))];

    const arrayOfYears = [];
    const startYear = calendarStartDate;
    for (let i = startYear; i >= 1920; i--) {
      arrayOfYears.push(i);
    }
    return (
      <CalendarHeader>
        <ArrowWrapper right={false} onMouseDown={gotoPreviousMonth}>
          <D.IconArrow style={{ transform: 'rotate(90deg)', marginRight: 4 }} />
        </ArrowWrapper>
        <MonthAndYear>
          <PStyled>{currentMonth}</PStyled>
          <DropDownContainer>
            <PStyledYear onClick={() => setDropdownOpen(true)}>
              {year}
            </PStyledYear>
            {dropdownOpen && (
              <DropDownMenu>
                {arrayOfYears.map((y: number) => (
                  <DropDownItem
                    key={y}
                    onClick={() => {
                      setYear(y);
                      setDropdownOpen(false);
                    }}
                  >
                    {y}
                  </DropDownItem>
                ))}
              </DropDownMenu>
            )}
          </DropDownContainer>
        </MonthAndYear>
        <ArrowWrapper right={true} onMouseDown={gotoNextMonth}>
          <D.IconArrow
            style={{ transform: 'rotate(-90deg)', marginRight: 4 }}
          />
        </ArrowWrapper>
      </CalendarHeader>
    );
  }

  function calculateMinimumAgeGap(date: Date) {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    return date > eighteenYearsAgo;
  }
  function showErrMessage() {
    const message =
      'you must be older than 18 to continue with this questionnaire';
    setErrMessage(message);
    setTimeout(() => {
      setErrMessage('');
    }, 750);
  }

  function renderCalendarDate(date: any[], index: number) {
    const _date = new Date(date.join('-'));
    const isCurrent = current
      ? isSameDay(_date, current)
      : isDefaultNextDelivery(_date);
    const inMonth = isSameMonth(_date, new Date([year, month, 1].join('/')));
    const restrictedDays = calculateMinimumAgeGap(_date);
    const onClick = gotoDate(_date);
    const props = {
      index,
      inMonth,
      restrictedDays,
      onClick: restrictedDays ? showErrMessage : onClick,
      title: _date.toDateString(),
    };

    function getDateComponent() {
      if (isCurrent) return HighlightedCalendarDate;
      if (restrictedDays) return SoonCalendarDate;

      return CalendarDate;
    }
    const DateComponent = getDateComponent();

    return (
      <DateComponent key={getDateISO(_date) || _date.getDate()} {...props}>
        <D.P>{_date.getDate()}</D.P>
      </DateComponent>
    );
  }
  const dayArr = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const errorDisplay = errMessage ? 'block' : 'none';
  return (
    <Container>
      <ContainerInner>
        {renderMonthAndYear()}
        <ErrMessageBox style={{ display: errorDisplay }}>
          {errMessage}
        </ErrMessageBox>
        <CalendarGrid>
          {dayArr.map((a, i) => (
            <DayLabel key={i}> {a}</DayLabel>
          ))}
          {getCalendarDates().map(renderCalendarDate)}
        </CalendarGrid>
      </ContainerInner>
    </Container>
  );
}

const PStyled = styled(D.P)`
  margin: 0 5px;
  font-size: 18px;
  border-bottom: 2px solid #fefce8;
  width: 96px;
`;

const PStyledYear = styled(PStyled)`
  background: ${D.creamGold};
  border: 1px solid grey;
  margin: 0 10px;
  border-radius: 2px;
  transition: all 0.4s ease-out;
  padding: 0 8px;
`;

const DropDownItem = styled(PStyled)`
  padding: 0 5px;
  transition: all 0.4s ease-out;
  margin-top: 0;
`;

const DropDownMenu = styled.div`
  position: absolute;
  background: #fefcea;
  top: 0px;
  width: 100%;
  height: 250px;
  overflow: scroll;
  border: 1px solid grey;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const ErrMessageBox = styled(D.P3)`
  position: absolute;
  background: white;
  width: 200px;
  border: 1px solid red;
  padding: 6px 15px;
  margin-bottom: 10px;
  bottom: 126px;
  right: 50px;
  z-index: 1;
`;

const DropDownContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const Container = styled.div`
  min-height: 392px;
  width: fit-content;
  border: 2px solid #00391e;
  z-index: 1;
  display: flex;
  flex: 1;
  @media only screen and (max-width: 768px) {
    min-height: 234px;
  }
`;

const ContainerInner = styled.div`
  max-width: 350px;
  width: 100%;
  padding: 30px;
  height: 392px;
  background-color: #fefcea;
  @media only screen and (max-width: 768px) {
    padding: 5px;
  }
`;

type ArrowWrapper = {
  right: boolean;
};

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: ${(props: ArrowWrapper) =>
    props.right ? 'flex-end' : 'flex-start'};
  user-select: none;
  &:hover {
    cursor: pointer;
  }
`;

const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #b1b59a;
  padding-bottom: 10px;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template: repeat(7, auto) / repeat(7, auto);
`;

const MonthAndYear = styled.div`
  font-weight: 500;
  text-align: center;
  user-select: none;
  display: flex;
  /* flex-direction: column; */
`;

type CalendarCell = {
  index: number;
  inMonth: boolean;
};

const CalendarCell = styled.div`
  text-align: center;
  align-self: center;
  user-select: none;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  margin: 3px;
  cursor: pointer;
  grid-column: ${(props: CalendarCell) => (props.index % 7) + 1} / span 1;
  grid-row: ${(props: CalendarCell) => Math.floor(props.index / 7) + 2} / span 1;
  display: ${(props: CalendarCell) => (props.inMonth ? `flex` : `none`)};
  @media only screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    margin: auto;
  }
`;

const DayLabel = styled(D.P)`
  color: #eda91f;
  text-align: center;
  align-self: center;
  user-select: none;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  margin: 3px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    margin: auto;
  }
`;

const CalendarDate = styled(CalendarCell)`
  transition: all 0.4s ease-out;
  :hover {
    color: white;
    background: #eda91f;
    p {
      color: white !important;
    }
  }
`;

const HighlightedCalendarDate = styled(CalendarDate)`
  background: #eda91f;
  p {
    color: white;
  }
`;

const SoonCalendarDate = styled(CalendarCell)`
  position: relative;
  cursor: default;
  p {
    color: rgba(0, 0, 0, 0.1);
  }
`;
