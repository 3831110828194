import React from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import Question from './QuesQuestion';
import { AnswerWrapper, QuestionAndAnswerContainer } from './SharedLayouts';

interface Props {
  question: any;
}

export default function ExitMessage({ question }: Props) {
  const { title, subtitle } = question;
  function goArticle() {
    window.open(
      'https://yourdaye.com/vitals/womens-health/pros-and-cons-of-contraception/',
      '_blank'
    );
  }
  const defaultTitle = 'Sorry, we are currently unable to prescribe for you.';
  const titleToShow = title || defaultTitle;
  const subTitleToShow = subtitle || '';
  return (
    <QuestionAndAnswerContainer>
      <Question title={titleToShow} subtitle={subTitleToShow} />
      <AnswerWrapper>
        <PStyled1>
          Thanks for answering the questionnaire. From what we can tell about
          your situation, it sounds like finding the best contraceptive method
          for you requires digging a little deeper.
        </PStyled1>
        <PStyled1>
          We recommend chatting to your GP or visiting a sexual health clinic to
          discuss your options.
        </PStyled1>

        <PStyled2>
          📚 In the meantime, if you want to find out more about contraception,
          check out{' '}
          <StyledLink onClick={goArticle}>
            Daye’s comprehensive guide to the pros and cons of different forms
            of contraception
          </StyledLink>
        </PStyled2>
      </AnswerWrapper>
    </QuestionAndAnswerContainer>
  );
}

const PStyled1 = styled(D.P)`
  padding-bottom: 20px;
  font-size: 14px;
`;
const PStyled2 = styled(D.P)`
  background: #cae2c3;
  font-size: 14px;
  padding: 18px;
`;

const StyledLink = styled.span`
  cursor: pointer;
  margin-bottom: 0;
  display: inline;
  margin-top: 0px;
  padding-top: 6px;
  text-decoration: underline;
  color: #01341a;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
`;
