import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BMIWeight from './BMIWeight';
import BMIHeight from './BMIHeight';
import Button, { QuestionnaireSubmitBtn } from './Button';
import Question from './QuesQuestion';
import { QuestionAndAnswerContainer, AnswerWrapper } from './SharedLayouts';

export default function BMI({ question, onChange }) {
  const {
    heightTitle,
    heightSubtitle,
    weightTitle,
    weightSubtitle,
    answer,
  } = question;
  let weightAnswer = '';
  let heightAnswer = '';
  if (answer) {
    weightAnswer = answer.weight;
    heightAnswer = answer.height;
  }
  const [bmiHeight, setBmiHeight] = useState(0);
  const [bmiWeight, setBmiWeight] = useState(0);

  return (
    <div>
      <QuestionAndAnswerContainer>
        <Question title={weightTitle} subtitle={weightSubtitle} />
        <BMIWeight onChange={setBmiWeight} answer={weightAnswer} />
      </QuestionAndAnswerContainer>
      <QuestionAndAnswerContainer>
        <Question title={heightTitle} subtitle={heightSubtitle} />
        <AnswerWrapper>
          <BMIHeight onChange={setBmiHeight} answer={heightAnswer} />
          <QuestionnaireSubmitBtn
            disabled={!bmiHeight || !bmiWeight}
            onClick={() => {
              onChange({ height: bmiHeight, weight: bmiWeight });
            }}
          >
            Submit
          </QuestionnaireSubmitBtn>
        </AnswerWrapper>
      </QuestionAndAnswerContainer>
    </div>
  );
}
