import React, { useState, useEffect } from 'react';

import { ButtonStyled, TextInputStyled, UnitWrapper } from './BMIWeight';
import { AnswerWrapper } from './SharedLayouts';
import { D } from '@dayetopia/library';
import styled from 'styled-components';
import UnitButtons from './BMIUnitButtons';

export default function BMIHeight({ onChange, answer }) {
  const [unit, setUnit] = useState('cm');
  const [valueInCm, setValueInCms] = useState(answer);
  const [valueInFt, setValueInFt] = useState();
  const [valueInInches, setValueInInches] = useState();

  const cmsPerInch = 2.54;
  const cmsPerFoot = 30.48;

  useEffect(() => {
    if (!valueInInches && !valueInFt) return;
    const inchesToCms = (valueInInches || 0) * cmsPerInch;
    const feetToCms = (valueInFt || 0) * cmsPerFoot;
    setValueInCms(inchesToCms + feetToCms);
  }, [valueInInches, valueInFt]);

  useEffect(() => {
    onChange(valueInCm);
  }, [valueInCm]);

  function handleLargeUnitChange(event) {
    const valueAsNumber = Number(event.target.value);
    if (isNaN(valueAsNumber)) return;

    if (unit === 'cm') {
      setValueInCms(valueAsNumber);
    } else {
      setValueInFt(valueAsNumber);
    }
  }

  function handleSmallUnitChange(event) {
    const valueAsNumber = Number(event.target.value);
    if (isNaN(valueAsNumber)) return;
    setValueInInches(valueAsNumber);
  }

  return (
    <AnswerWrapper>
      <InputWrapper>
        <TextInputStyled
          placeholder={`0`}
          value={unit === 'cm' ? valueInCm : valueInFt}
          onChange={handleLargeUnitChange}
        />
        <P3Styled> {unit}</P3Styled>
      </InputWrapper>
      {unit === 'ft' && (
        <InputWrapper>
          <TextInputStyled
            placeholder={`0`}
            value={valueInInches}
            onChange={handleSmallUnitChange}
          />
          <P3Styled> in</P3Styled>
        </InputWrapper>
      )}
      <UnitButtons
        unitOne={{ text: 'ft', id: 'ft' }}
        unitTwo={{ text: 'cm', id: 'cm' }}
        onChange={setUnit}
        currentUnit={unit}
      />
    </AnswerWrapper>
  );
}

const P3Styled = styled(D.P)`
  margin-left: -45px;
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
`;
