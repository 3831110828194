import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextInput } from './TextInput';
import { QuestionnaireSubmitBtn } from './Button';
import Question from './QuesQuestion';
import { AnswerWrapper, QuestionAndAnswerContainer } from './SharedLayouts';

import { Calendar } from './Calendar';
import { zeroPad } from '../utils/calendar';

export default function DateInput({ onChange, answer, title, subtitle }) {
  const today = new Date();
  const [selectedDay, setSelectedDay] = useState(today.getDate());
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());
  const [calenderOpen, setCalenderOpen] = useState(false);

  useEffect(() => {
    if (answer) {
      const answerArr = answer.chosenDate.split('-');
      const year = answerArr[0];
      const day = answerArr[2];
      const month = answerArr[1];
      setSelectedDay(day);
      setSelectedMonth(month);
      setSelectedYear(year);
    }
  }, []);
  function setFormattedSelectedDate(d) {
    const selectedYear = Number(d.getFullYear());
    const selectedMonth = Number(d.getMonth() + 1);
    const selectedDay = Number(d.getDate());

    setSelectedDay(selectedDay);
    setSelectedMonth(selectedMonth);
    setSelectedYear(selectedYear);
  }
  const dateAsDate = new Date(selectedYear, selectedMonth - 1, selectedDay);
  const dateAsString = `${zeroPad(selectedDay, 2)}-${zeroPad(
    selectedMonth,
    2
  )}-${selectedYear}`;

  const isDefaultDate = today.getFullYear() === selectedYear;
  const valueToShow = isDefaultDate ? '' : dateAsString;
  return (
    <QuestionAndAnswerContainer>
      <Question title={title} subtitle={subtitle} />
      <AnswerWrapperStyled>
        <InputWrapper>
          <TextInputStyled
            value={valueToShow}
            readOnly
            placeholder="DD-MM-YYYY"
            type="text"
            onClick={() => setCalenderOpen(true)}
          />
          {calenderOpen && (
            <Calendar
              current={dateAsDate}
              setCurrent={setFormattedSelectedDate}
              closeCalendar={() => setCalenderOpen(false)}
            />
          )}
        </InputWrapper>

        <QuestionnaireSubmitBtn
          disabled={!(selectedDay && selectedMonth && selectedYear)}
          onClick={() => {
            const toDate = `${selectedMonth}-${selectedDay}-${selectedYear}`;
            onChange({ chosenDate: toDate });
          }}
        >
          Submit
        </QuestionnaireSubmitBtn>
      </AnswerWrapperStyled>
    </QuestionAndAnswerContainer>
  );
}

const TextInputStyled = styled(TextInput)`
  @media only screen and (max-width: 500px) {
    width: 300px;
  }
`;

const AnswerWrapperStyled = styled(AnswerWrapper)`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  position: relative;
`;
