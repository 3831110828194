import React, { useContext } from 'react';
import styled from 'styled-components';
// import NavProgress from '../components/NavProgress';
import { D } from '@dayetopia/library';
import { navigate } from 'gatsby';
import { Context } from '../components/Context';

interface Props {
  questionnaireComplete: boolean;
  questionArr: any[];
}

export default function FinalSubmitBtn({
  questionnaireComplete,
  questionArr,
}: Props) {
  const { dispatch } = useContext(Context);

  async function saveQuestions() {
    console.log('saveQuestions');
    const questionAnswers = JSON.stringify(questionArr);
    console.log(questionAnswers);
    dispatch({ type: 'setQuestionAnswers', data: questionAnswers });
    navigate('/checking-history');
  }

  return (
    <BtnWrapper>
      <SubmitBtn disabled={!questionnaireComplete} onClick={saveQuestions}>
        Submit answers
      </SubmitBtn>
    </BtnWrapper>
  );
}

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 500px) {
    max-width: 300px;
    margin: auto;
  }
`;
const SubmitBtn = styled(D.Button)`
  margin-top: 40px;
`;
