import React from 'react';
import styled from 'styled-components';
import { D } from '@dayetopia/library';
import DayeChat from '../images/icon-daye-chat.svg';

type Props = {
  title: string;
  subtitle?: string;
};

export default function Question({ title, subtitle }: Props) {
  return (
    <QuestionWrapper>
      <QuestionBoxWrapper>
        <PStyled>{title}</PStyled>
        {subtitle && <PStyled2>{subtitle}</PStyled2>}
        <SquareImg />
      </QuestionBoxWrapper>
      <ImgStyled />
    </QuestionWrapper>
  );
}
const PStyled2 = styled(D.P)`
  font-family: BrownStd;
  font-size: 14px;
  margin: 0;
  color: grey;
`;
const ImgStyled = styled(DayeChat)`
  position: absolute;
  left: 22px;
  @media only screen and (max-width: 500px) {
    left: 0px;
  }
`;
const QuestionWrapper = styled.div`
  position: relative;
  padding-bottom: 50px;
  width: 40%;
  @media only screen and (max-width: 500px) {
    margin: auto;
    width: 90%;
    margin-bottom: 20px;
  }
`;
const PStyled = styled(D.P)`
  font-size: 18px;
  margin: 0 auto;
`;

const SquareImg = styled.div`
  position: absolute;
  bottom: -8px;
  height: 15px;
  width: 15px;
  background: #fefcea;
  border-bottom: 2px solid #00391e;
  border-right: 2px solid #00391e;
  transform: rotate(45deg);
  @media only screen and (max-width: 500px) {
    bottom: -9px;
  }
`;
const QuestionBoxWrapper = styled.div`
  background: #fefcea;
  border: 2px solid #00391e;
  position: relative;
  padding: 10px;
  margin: 0 20px 20px 20px;
  width: fit-content;

  @media only screen and (max-width: 500px) {
    margin: 0;
    margin-bottom: 20px;
  }
`;
