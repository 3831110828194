import React from 'react';
import styled from 'styled-components';

export default function RadioButton(props: { selected: boolean }) {
  return <StyledRadio>{props.selected && <RadioFill />}</StyledRadio>;
}

const StyledRadio = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #003a1b;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
`;

const RadioFill = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #003a1b;
  /* margin-left: 1px; */
  /* margin-top: 1px; */
`;
