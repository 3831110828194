import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import TableRadioItem from './TableRadioItem';
import Question from './QuesQuestion';
import { AnswerWrapper, QuestionAndAnswerContainer } from './SharedLayouts';

export default function Table({
  onChange,
  answer,
  answerOptions,
  title,
  subtitle,
}) {
  const [value, setValue] = useState({ id: -1 });

  useEffect(() => {
    if (answer) {
      setValue(answer);
    }
  }, []);

  return (
    <QuestionAndAnswerContainer>
      <Question title={title} subtitle={subtitle} />
      <StyledAnswerWrapper>
        {answerOptions.map((o, i) => {
          const checked = value.id === o.id;
          return (
            <TableRadioItem
              item={o}
              key={i}
              checked={checked}
              onClick={() => {
                setValue({ id: o.id });
                onChange(o);
              }}
            />
          );
        })}
      </StyledAnswerWrapper>
    </QuestionAndAnswerContainer>
  );
}

const StyledAnswerWrapper = styled(AnswerWrapper)`
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-column-gap: 20px;
  grid-row-gap: 24px;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
