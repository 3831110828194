import styled from 'styled-components';

export const AnswerWrapper = styled.div`
  width: 50%;

  @media only screen and (max-width: 800px) {
    max-width: 300px;
    min-width: 300px;
    margin: auto;
    grid-template-columns: 1fr;
  }
`;

export const QuestionAndAnswerContainer = styled.div`
  width: 100%;
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    padding-top: 100px;
  }
`;
