import React from 'react';
import styled from 'styled-components';
import Button from './Button';

interface Unit {
  text: string;
  id: string;
}

interface Props {
  onChange: (a: string) => void;
  unitOne: Unit;
  unitTwo: Unit;
  currentUnit: string;
}

export default function UnitButtons({
  onChange,
  unitOne,
  unitTwo,
  currentUnit,
}: Props) {
  return (
    <>
      <ButtonStyled
        active={currentUnit === unitOne.id}
        onClick={() => onChange(unitOne.id)}
      >
        {unitOne.text}
      </ButtonStyled>
      <ButtonStyled
        active={currentUnit === unitTwo.id}
        onClick={() => onChange(unitTwo.id)}
      >
        {unitTwo.text}
      </ButtonStyled>
    </>
  );
}

interface ButtonStyled {
  active: boolean;
}
const ButtonStyled = styled(Button)`
  text-transform: lowercase;
  margin: 0;
  outline: none;
  padding: 0 20px;
  ${({ active }: ButtonStyled) =>
    active
      ? `background-color: #00391E`
      : `background-color: #eaf5e8;
color: #00391E;
margin: 0;
border: 2px solid #00391E;`}
`;
